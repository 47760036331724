<template>

  <router-view />

  <van-tabbar v-model="active" active-color="#3399FF" inactive-color="#000">
    <van-tabbar-item icon="home-o" to="/">Home</van-tabbar-item>
    <!-- <van-tabbar-item icon="search">Search</van-tabbar-item> -->
    <van-tabbar-item icon="info-o" to="/about">About</van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { ref } from "vue";
import { Toast } from "vant";

export default {
  components: {
    [Toast.name]: Toast,
  },
  methods: {},
  setup() {
    const onClickLeft = () => Toast("返回");
    const onClickRight = () => Toast("按钮");

    const active = ref(0);

    return {
      active,
      onClickLeft,
      onClickRight,
    };
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
