<template>
  <div class="home" v-if="GPS_switch">
    <van-image
      style="margin-top: 30%"
      src="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-6b96248f-9e27-425f-9eef-5ddb9ef26220/9d6d0ef4-317f-4ddd-a13c-667d3cf9e86e.png"
    ></van-image>

    <h3>Find discounts nearby.</h3>

    <van-row>
      <van-col span="2"></van-col>
      <van-col span="20"
        ><van-button
          round
          icon="aim"
          type="primary"
          size="large"
          @click="getTheNearestStore()"
          >Search</van-button
        ></van-col
      >
      <van-col span="2"></van-col>
    </van-row>
  </div>

  <div v-else>
    <van-empty
      class="custom-image"
      image="https://vkceyugu.cdn.bspapp.com/VKCEYUGU-6b96248f-9e27-425f-9eef-5ddb9ef26220/4d0e36a1-d72e-440e-aba3-146b007d0d5b.png"
      description="Ooops! Where r u ?"
    />
  </div>
</template>

<script>
// @ is an alias to /src
const axios = require("axios");
import { Toast } from "vant";
import { GPS } from "../../methods/gps.js";
import { ref } from "vue";

let latitude = 0.0;
let longitude = 0.0;

export default {
  name: "Home",
  data() {
    return {
      info: {},
      AroundStoreName: "",
      GPS_switch: true,
      storesNearby: 0,
    };
  },
  components: {
    // GoogleMap,
    // Marker,
  },
  setup() {
    const onSubmit = () => Toast("点击按钮");
    const activeNames = ref(["1"]);
    // const center = { lat: 40.689247, lng: -74.044502 };

    return {
      onSubmit,
      activeNames,
      // center,
    };
  },
  methods: {
    getPosition() {
      let that = this;
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              latitude = position.coords.latitude;
              longitude = position.coords.longitude;
              let data = {
                latitude: latitude,
                longitude: longitude,
              };

              that.GPS_switch = true;
              console.log(longitude, latitude);
              resolve(data);
            },
            function () {
              that.GPS_switch = false;
              console.log("Fail to get GPS info.");
              Toast("Please turn on GPS");
              reject(arguments);
            }
          );
        } else {
          console.log("Fail to get GPS info.");
          Toast("Please set GPS on.");
          reject("你的浏览器不支持当前地理位置信息获取");
        }
      });
    },
    getDistance(latA, lngA, latB, lgnB) {
      return GPS.distance(latA, lngA, latB, lgnB);
    },
    getTheNearestStore() {
      var res = {};
      axios.get("https://api.dr.search.ren/getStores").then((response) => {
        res = response;
        console.log(res);

        console.log(res.data.stores);
        let stores = res.data.stores;

        let that = this;
        console.log(latitude+","+longitude)
        for (let i = 0; i < stores.length; i++) {
          if (
            this.getDistance(
              latitude,
              longitude,
              stores[i].latitude,
              stores[i].longitude
            ) < 150.0
          ) {
            that.storesNearby++;
            console.log("close");
            this.$router.push({
              path: "detail",
              query: {
                uuid: stores[i].uuid,
                name: stores[i].name,
                imgurl: stores[i].imgurl,
              },
            });
            break;
          } else {
            console.log(
              "Distance:" +
                this.getDistance(
                  latitude,
                  longitude,
                  stores[i].latitude,
                  stores[i].longitude
                )
            );
          }

          console.log(this.storesNearby);
        }

        if (this.storesNearby == 0) {
          Toast("Sorry, no discount here.");
        }
      });

      // //'user' 是路径名称
      // this.$router.push({
      //   path: "detail",
      //   query: { uuid: "qwheui", name: "La Postte" },
      // });
    },
  },
  created() {
    this.getPosition();
  },
};
</script>

<style>
.custom-image .van-empty__image {
  margin-top: 40%;
}
</style>