import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.css';
import { Lazyload } from 'vant';
import { Overlay } from 'vant';

let app = createApp(App);

app.use(router).use(Vant).use(Lazyload)

app.use(Overlay);

app.mount("#app");

